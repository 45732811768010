/* Class namees
.sp_dropdown
.sp_dropdown-btn
.sp_dropdown-item

*/

.sp_dropdown{
   width: 140px; 
   position: relative;
   user-select: none;
   cursor: pointer;
   /* margin-right: 16px; */
}
/* Agregar flechita al boton que indique desplegable */
.sp_dropdown .sp_dropdown-btn{

    background-color: transparent;
    box-shadow: 3px 3px 10px 6px rgba(0,0,0,0.06);
    color: #E97355;
    border-radius: 32px;
    border: 1px solid #E97355;
    padding: 0 20px;

    display: flex;
    align-items: center;
    justify-content: space-between; 
    width: 100%;

}

.sp_dropdown .sp_dropdown-content{
    position: absolute;
    top: 110%;
    background-color: #E97355;
    box-shadow: 3px 3px 10px 6px rgba(0,0,0,0.06);
    border: 1px solid #E97355;

    border-radius: 12px;
    width: 140px;
    color: white;
}
.sp_dropdown .sp_dropdown-content .sp_dropdown-item{
    padding: 10px;
    border-radius: 12px;
    border: none;
    cursor: pointer;
    transition: all 0.2s;
    text-align: center;
}
.sp_dropdown .sp_dropdown-content .sp_dropdown-item:hover{
    color:  #E97355;
    background-color: white;
}

/* Contenedor del menu hamburguesa y el tranlator */
.sp-container_translator_icon-nav{
    width: 150px;
}
@media (max-width: 991px){
    .sp_dropdown{
        font-size: 14px;
     }
    
    .sp_dropdown .sp_dropdown-btn{
        padding: 5px;
        width: 88px;
        justify-content: space-around;
    }
    .sp_dropdown .sp_dropdown-content{
        width: 88px;
    }

}