/* 12.01 About CSS */

.about-image-area{
    position: relative;
    z-index: 9;
    height: 100%;
    margin-right: 45px;
    &.right-0{
        .about-image{
            right: 0;
        }
    }
    .about-image{
        position: relative;
        top: 0;
        right: 45px;
        float: right;
        z-index: 9;
        &.right-n50{
            right: -50px !important;
            // Responsive
            @media #{$laptop-device}{
                right: 0 !important;
            }
            @media #{$desktop-device}{
                right: 0 !important;
            }
            @media #{$tablet-device}{
                right: 0 !important;
            }
            @media #{$large-mobile}{
                right: 0 !important;
                width: 250px;
            }
        }
        // Responsive
        @media #{$desktop-device}{
            right: 0;
        }
        @media #{$tablet-device}{
            right: 24px;
        }
        @media #{$large-mobile}{
            right: 0;
        }
        @media #{$extra-small-mobile}{
            right: 0;
        }
        &:nth-child(2){
            position: absolute;
            top: 152px;
            left: 0;
            z-index: 8;
            float: left;
            text-align: left;
            @media #{$tablet-device}{
                left: 24px;
                top: 64px;
            }
            @media #{$large-mobile}{
                left: 0;
                top: 40px;
            }
            @media #{$extra-small-mobile}{
                left: 0;
            }
        }
        // img{
        //     border-radius: 5px;
        //     box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.05), 0 1.5rem 2.2rem rgba(0, 0, 0, 0.1);
        //     transition: all 1.5s cubic-bezier(0, 0, 0.2, 1);
            
        // }
        &:hover {
            // & img {
            //     box-shadow: 0 0.5rem 1.2rem rgba(0, 0, 0, 0.1), 0 2rem 3rem rgba(0, 0, 0, 0.15);
                
            // }
            & .about-img-test{
                box-shadow: 0 0.5rem 1.2rem rgba(0, 0, 0, 0.1), 0 2rem 3rem rgba(0, 0, 0, 0.15);
            }
            & .overlay-effect{
                box-shadow: 0 0.5rem 1.2rem rgba(0, 0, 0, 0.1), 0 2rem 3rem rgba(0, 0, 0, 0.15);
            }
        }
        .about-img-test{
            width: 400px;
            height: 280px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            border-radius: 5px; 
            position: absolute;
            box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.05), 0 1.5rem 2.2rem rgba(0, 0, 0, 0.1);
            transition: all 1.5s cubic-bezier(0, 0, 0.2, 1);
             // Responsive
            @media #{$tablet-device}{
                width: 352px;
                height: 232px;
            }
            @media #{$large-mobile}{
                width: 316px;
                height: 192px;
            }
            @media #{$extra-small-mobile}{
                width: 256px;
                height: 176px;
            }
                  
        }
        .overlay-effect{
            width: 400px;
            height: 280px;
            opacity: 0.3;
            background-color: #E97355;
            border-radius: 5px; 
            box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.05), 0 1.5rem 2.2rem rgba(0, 0, 0, 0.1);
            transition: all 1.5s cubic-bezier(0, 0, 0.2, 1);
            // Responsive
            @media #{$tablet-device}{
                width: 352px;
                height: 232px;
            }
            @media #{$large-mobile}{
                width: 316px;
                height: 192px;
            }
            @media #{$extra-small-mobile}{
                width: 256px;
                height: 176px;
            }
            
        }
    }

    & .shape {
        position: absolute;
        z-index: 1;

        &-1 {
            right: 0;
            top: 50px;
            z-index: -1;
        }
    }

    // Responsive
    @media #{$desktop-device}{
        margin-right: 0;
        & .shape {
            position: absolute;
            z-index: -1;
    
            &-1 {
                right: 0;
                top: 50px;
                z-index: -1;
                span{
                    width: 200px;
                }
                
            }
        }
    }
    @media #{$tablet-device}{
        margin-right: 0;
        & .shape {
            position: absolute;
            z-index: -1;
    
            &-1 {
                right: 0;
                top: 50px;
                z-index: -1;
                span{
                    width: 200px;
                }
            }
        }
    }

    @media #{$large-mobile}{
        margin-right: 0;
        & .shape {
            position: absolute;
            z-index: -1;
    
            &-1 {
                right: 0;
                top: 50px;
                z-index: -1;
                span{
                    width: 200px;
                }
            }
        }
    }
}

.about-content-area{
    p{
        max-width: 420px;
        margin-bottom: 0;
        // Responsive
        @media #{$tablet-device}{
            max-width: 100%;
        }
        @media #{$large-mobile}{
            max-width: 100%;
        }
    }
    // Responsive
    @media #{$tablet-device}{
        margin-top: 150px;
    }
    @media #{$large-mobile}{
        margin-top: 150px;
    }
}

.about-funfact{
    .number{
        font-size: 41px;
        line-height: 1;
        color: $primary;
        margin-bottom: 15px;
    }
    .text{
        font-size: 20px;
        line-height: 1.5;
        margin-bottom: 15px;
    }
}

.about-shape-animation{
    position: relative;

    & .shape {
        position: absolute;
        z-index: 1;

        &-1 {
            top: auto;
            bottom: 0px;
            right: -35px;
            z-index: -1;
        }
    }

    // Responsive
    @media #{$laptop-device}{
        & .shape {
    
            &-1 {
                bottom: -60px;
                right: 0px;
                z-index: -1;
            }
        }
    }
    @media #{$desktop-device}{
        & .shape {
    
            &-1 {
                bottom: -60px;
                right: 0px;
                z-index: -1;
            }
        }
    }
    @media #{$tablet-device}{
        & .shape {
    
            &-1 {
                bottom: -60px;
                right: 0px;
                z-index: -1;
            }
        }
    }
    @media #{$large-mobile}{
        & .shape {
    
            &-1 {
                bottom: -60px;
                right: 0px;
                z-index: -1;
            }
        }
    }
}

.about-content-full{
    .title{
        font-size: 42px;
        font-weight: 600;
        margin-bottom: 25px;
        // Responsive
        @media #{$tablet-device}{
            font-size: 26px;
            line-height: 32px;
        }
        @media #{$large-mobile}{
            font-size: 24px;
            line-height: 30px;
        }
    }
    p{
        margin-bottom: 20px;
    }
}
