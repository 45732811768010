/* Style Of swiper and form */
.sp-section_slider{
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.5);
        
    }
.notShow {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: opacity 0.5s ease-in-out, max-height 0.5s ease-in-out;
  }
  
  .show {
    opacity: 1;
    max-height: 1000px; /* ajustar según la altura máxima del contenido */
    overflow: visible;
    transition: opacity 0.5s ease-in-out, max-height 0.5s ease-in-out;
  }
  
.sp-container_slide{
    background-color: #212121 !important;
    grid-row-gap: 100px;
    row-gap: 100px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-content: center;
    align-items: center;
    gap: 30px;
    padding: 20px;

}
#foo{
    width: 100px;
    height: 100px;
}
.sp-box_input{
    cursor: pointer;
    height: 250px;
    max-width: 320px;
    min-width: 280px; 
    width: 100%; 
    font-size: 13px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 10px;
    text-align: center;
    z-index: 20px;
   
}
.sp-box_input label{
    row-gap: 15px;

    font-size: 20px;
    transition: background-color 0.3s ease-in-out;
}

.sp-box_input:hover{
    background-color: #7272728c;
    border-radius: 10px;
}
.clicked{
    background-color: #7272728c;
}
.sp-box_input label input{
    height: 20px;
    width: 20px;
}
/* Change Styles of Swiper */
.swiper-container{
    max-width: 1140px;

    width: 100%;
    min-width: 100%;
}
.swiper-pagination-fraction{
    color:  rgba(255, 255, 255, 0.5);
    font-weight: 300;
}
.swiper-pagination{
    position: relative;
    font-size: 24px;
    margin-bottom: 20px;
}
.swiper-container >.swiper-button-prev, .swiper-container > .swiper-button-next, .swiper-container > .swiper-button-prev:after, .swiper-container > .swiper-button-next:after {
    color: #E97355 !important;
    cursor: pointer !important;
    font-size: 32px;
    font-weight: 300 !important; 
}


.w-container{
max-width: 1180px;
min-width: 320px;
width: 100%;
}

.swiper-container {
  display: flex;
  flex-direction: column;
  align-items: center;

}
.w-15{
    width: 15%;
}
.w-85{
    width: 85%;
}
/* Laptop */
@media (max-width: 1200px){
    .sp-box_input{
        height: auto;
    }
}
/* Tablet and Mobile */
@media (max-width: 776px){
    .sp-container_slide{
       padding: 0;
    }
    .sp-box_input label{
        flex-direction: row !important;
        justify-content: start !important;
        column-gap: 20px;
        padding: 0 15px;
        font-size: 16px;
    }
    .sp-box_input{
        height: auto;
    }
    .sp-box_input label input{
        height: 16px;
        width: 16px;
    }
    #foo{
        width: 40px;
        height: 40px;
    }
    .font-sm{
        font-size: 18px;
    }

}


.fade-in {
    opacity: 0;
    height: 0;
    transition: all 0.5s ease-in-out;
  }
  
  .fade-in.show {
    opacity: 1;
    height: auto;
  }

  /* Loader */
  .custom-loader {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: 
      radial-gradient(farthest-side,#E97355 94%,#0000) top/8px 8px no-repeat,
      conic-gradient(#0000 30%,#E97355);
    -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 8px),#000 0);
    animation:s3 1s infinite linear;
  }
  
  @keyframes s3{ 
    100%{transform: rotate(1turn)}
  }
    .showw .icon svg path {
    stroke: #28a745;
  }